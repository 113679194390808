"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UltraLightNode__factory = exports.Relayer__factory = exports.Endpoint__factory = void 0;
/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
var Endpoint__factory_1 = require("./Endpoint__factory");
Object.defineProperty(exports, "Endpoint__factory", { enumerable: true, get: function () { return Endpoint__factory_1.Endpoint__factory; } });
var Relayer__factory_1 = require("./Relayer__factory");
Object.defineProperty(exports, "Relayer__factory", { enumerable: true, get: function () { return Relayer__factory_1.Relayer__factory; } });
var UltraLightNode__factory_1 = require("./UltraLightNode__factory");
Object.defineProperty(exports, "UltraLightNode__factory", { enumerable: true, get: function () { return UltraLightNode__factory_1.UltraLightNode__factory; } });
