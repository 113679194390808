"use strict";
/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UltraLightNode__factory = void 0;
const ethers_1 = require("ethers");
const _abi = [
    {
        inputs: [
            {
                internalType: 'address',
                name: '_endpoint',
                type: 'address',
            },
        ],
        stateMutability: 'nonpayable',
        type: 'constructor',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'uint16',
                name: 'chainId',
                type: 'uint16',
            },
            {
                indexed: false,
                internalType: 'address',
                name: 'lib',
                type: 'address',
            },
        ],
        name: 'AddInboundProofLibraryForChain',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'address',
                name: 'userApplication',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'configType',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'bytes',
                name: 'newConfig',
                type: 'bytes',
            },
        ],
        name: 'AppConfigUpdated',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'uint16',
                name: 'chainId',
                type: 'uint16',
            },
            {
                indexed: false,
                internalType: 'uint16',
                name: 'proofType',
                type: 'uint16',
            },
        ],
        name: 'EnableSupportedOutboundProof',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'uint16',
                name: 'srcChainId',
                type: 'uint16',
            },
            {
                indexed: false,
                internalType: 'address',
                name: 'oracle',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'confirmations',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'bytes32',
                name: 'blockhash',
                type: 'bytes32',
            },
        ],
        name: 'HashReceived',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'previousOwner',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'newOwner',
                type: 'address',
            },
        ],
        name: 'OwnershipTransferred',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'uint16',
                name: 'chainId',
                type: 'uint16',
            },
            {
                indexed: false,
                internalType: 'bytes',
                name: 'payload',
                type: 'bytes',
            },
        ],
        name: 'Packet',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'uint16',
                name: 'chainId',
                type: 'uint16',
            },
            {
                indexed: false,
                internalType: 'uint64',
                name: 'nonce',
                type: 'uint64',
            },
            {
                indexed: false,
                internalType: 'uint16',
                name: 'outboundProofType',
                type: 'uint16',
            },
            {
                indexed: false,
                internalType: 'bytes',
                name: 'adapterParams',
                type: 'bytes',
            },
        ],
        name: 'RelayerParams',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'uint16',
                name: 'chainId',
                type: 'uint16',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'size',
                type: 'uint256',
            },
        ],
        name: 'SetChainAddressSize',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'uint16',
                name: 'chainId',
                type: 'uint16',
            },
            {
                indexed: false,
                internalType: 'uint16',
                name: 'proofType',
                type: 'uint16',
            },
            {
                indexed: false,
                internalType: 'bytes',
                name: 'adapterParams',
                type: 'bytes',
            },
        ],
        name: 'SetDefaultAdapterParamsForChainId',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'uint16',
                name: 'chainId',
                type: 'uint16',
            },
            {
                indexed: false,
                internalType: 'uint16',
                name: 'inboundProofLib',
                type: 'uint16',
            },
            {
                indexed: false,
                internalType: 'uint64',
                name: 'inboundBlockConfirm',
                type: 'uint64',
            },
            {
                indexed: false,
                internalType: 'address',
                name: 'relayer',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint16',
                name: 'outboundProofType',
                type: 'uint16',
            },
            {
                indexed: false,
                internalType: 'uint16',
                name: 'outboundBlockConfirm',
                type: 'uint16',
            },
            {
                indexed: false,
                internalType: 'address',
                name: 'oracle',
                type: 'address',
            },
        ],
        name: 'SetDefaultConfigForChainId',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'address',
                name: 'tokenAddress',
                type: 'address',
            },
        ],
        name: 'SetLayerZeroToken',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'address',
                name: 'relayerFeeContract',
                type: 'address',
            },
        ],
        name: 'SetRelayerFeeContract',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'uint16',
                name: 'chainId',
                type: 'uint16',
            },
            {
                indexed: false,
                internalType: 'bytes32',
                name: 'uln',
                type: 'bytes32',
            },
        ],
        name: 'SetRemoteUln',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'address',
                name: 'treasuryAddress',
                type: 'address',
            },
        ],
        name: 'SetTreasury',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'uint8',
                name: '_type',
                type: 'uint8',
            },
            {
                indexed: false,
                internalType: 'address',
                name: '_owner',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'address',
                name: '_msgSender',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'address',
                name: '_to',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: '_amount',
                type: 'uint256',
            },
        ],
        name: 'WithdrawNative',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'address',
                name: '_msgSender',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'address',
                name: '_to',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: '_amount',
                type: 'uint256',
            },
        ],
        name: 'WithdrawZRO',
        type: 'event',
    },
    {
        inputs: [],
        name: 'BP_DENOMINATOR',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'CONFIG_TYPE_INBOUND_BLOCK_CONFIRMATIONS',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'CONFIG_TYPE_INBOUND_PROOF_LIBRARY_VERSION',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'CONFIG_TYPE_ORACLE',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'CONFIG_TYPE_OUTBOUND_BLOCK_CONFIRMATIONS',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'CONFIG_TYPE_OUTBOUND_PROOF_TYPE',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'CONFIG_TYPE_RELAYER',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'WITHDRAW_TYPE_ORACLE_QUOTED_FEES',
        outputs: [
            {
                internalType: 'uint8',
                name: '',
                type: 'uint8',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'WITHDRAW_TYPE_RELAYER_QUOTED_FEES',
        outputs: [
            {
                internalType: 'uint8',
                name: '',
                type: 'uint8',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'WITHDRAW_TYPE_TREASURY_PROTOCOL_FEES',
        outputs: [
            {
                internalType: 'uint8',
                name: '',
                type: 'uint8',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint16',
                name: '_chainId',
                type: 'uint16',
            },
            {
                internalType: 'address',
                name: '_library',
                type: 'address',
            },
        ],
        name: 'addInboundProofLibraryForChain',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
            {
                internalType: 'uint16',
                name: '',
                type: 'uint16',
            },
        ],
        name: 'appConfig',
        outputs: [
            {
                internalType: 'uint16',
                name: 'inboundProofLibraryVersion',
                type: 'uint16',
            },
            {
                internalType: 'uint64',
                name: 'inboundBlockConfirmations',
                type: 'uint64',
            },
            {
                internalType: 'address',
                name: 'relayer',
                type: 'address',
            },
            {
                internalType: 'uint16',
                name: 'outboundProofType',
                type: 'uint16',
            },
            {
                internalType: 'uint64',
                name: 'outboundBlockConfirmations',
                type: 'uint64',
            },
            {
                internalType: 'address',
                name: 'oracle',
                type: 'address',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint16',
                name: '',
                type: 'uint16',
            },
        ],
        name: 'chainAddressSizeMap',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint16',
                name: '',
                type: 'uint16',
            },
            {
                internalType: 'uint16',
                name: '',
                type: 'uint16',
            },
        ],
        name: 'defaultAdapterParams',
        outputs: [
            {
                internalType: 'bytes',
                name: '',
                type: 'bytes',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint16',
                name: '',
                type: 'uint16',
            },
        ],
        name: 'defaultAppConfig',
        outputs: [
            {
                internalType: 'uint16',
                name: 'inboundProofLibraryVersion',
                type: 'uint16',
            },
            {
                internalType: 'uint64',
                name: 'inboundBlockConfirmations',
                type: 'uint64',
            },
            {
                internalType: 'address',
                name: 'relayer',
                type: 'address',
            },
            {
                internalType: 'uint16',
                name: 'outboundProofType',
                type: 'uint16',
            },
            {
                internalType: 'uint64',
                name: 'outboundBlockConfirmations',
                type: 'uint64',
            },
            {
                internalType: 'address',
                name: 'oracle',
                type: 'address',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint16',
                name: '_chainId',
                type: 'uint16',
            },
            {
                internalType: 'uint16',
                name: '_proofType',
                type: 'uint16',
            },
        ],
        name: 'enableSupportedOutboundProof',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'endpoint',
        outputs: [
            {
                internalType: 'contract ILayerZeroEndpoint',
                name: '',
                type: 'address',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint16',
                name: '_chainId',
                type: 'uint16',
            },
            {
                internalType: 'address',
                name: '_ua',
                type: 'address',
            },
            {
                internalType: 'bytes',
                name: '_payload',
                type: 'bytes',
            },
            {
                internalType: 'bool',
                name: '_payInZRO',
                type: 'bool',
            },
            {
                internalType: 'bytes',
                name: '_adapterParams',
                type: 'bytes',
            },
        ],
        name: 'estimateFees',
        outputs: [
            {
                internalType: 'uint256',
                name: 'nativeFee',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'zroFee',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint16',
                name: '_chainId',
                type: 'uint16',
            },
            {
                internalType: 'address',
                name: 'userApplicationAddress',
                type: 'address',
            },
        ],
        name: 'getAppConfig',
        outputs: [
            {
                components: [
                    {
                        internalType: 'uint16',
                        name: 'inboundProofLibraryVersion',
                        type: 'uint16',
                    },
                    {
                        internalType: 'uint64',
                        name: 'inboundBlockConfirmations',
                        type: 'uint64',
                    },
                    {
                        internalType: 'address',
                        name: 'relayer',
                        type: 'address',
                    },
                    {
                        internalType: 'uint16',
                        name: 'outboundProofType',
                        type: 'uint16',
                    },
                    {
                        internalType: 'uint64',
                        name: 'outboundBlockConfirmations',
                        type: 'uint64',
                    },
                    {
                        internalType: 'address',
                        name: 'oracle',
                        type: 'address',
                    },
                ],
                internalType: 'struct UltraLightNode.ApplicationConfiguration',
                name: '',
                type: 'tuple',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_oracle',
                type: 'address',
            },
            {
                internalType: 'uint16',
                name: '_remoteChainId',
                type: 'uint16',
            },
            {
                internalType: 'bytes32',
                name: '_lookupHash',
                type: 'bytes32',
            },
        ],
        name: 'getBlockHeaderData',
        outputs: [
            {
                components: [
                    {
                        internalType: 'uint256',
                        name: 'confirmations',
                        type: 'uint256',
                    },
                    {
                        internalType: 'bytes32',
                        name: 'data',
                        type: 'bytes32',
                    },
                ],
                internalType: 'struct UltraLightNode.BlockData',
                name: 'blockData',
                type: 'tuple',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint16',
                name: '_chainId',
                type: 'uint16',
            },
            {
                internalType: 'address',
                name: 'userApplicationAddress',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: '_configType',
                type: 'uint256',
            },
        ],
        name: 'getConfig',
        outputs: [
            {
                internalType: 'bytes',
                name: '',
                type: 'bytes',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
            {
                internalType: 'uint16',
                name: '',
                type: 'uint16',
            },
            {
                internalType: 'bytes32',
                name: '',
                type: 'bytes32',
            },
        ],
        name: 'hashLookup',
        outputs: [
            {
                internalType: 'uint256',
                name: 'confirmations',
                type: 'uint256',
            },
            {
                internalType: 'bytes32',
                name: 'data',
                type: 'bytes32',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint16',
                name: '',
                type: 'uint16',
            },
            {
                internalType: 'uint16',
                name: '',
                type: 'uint16',
            },
        ],
        name: 'inboundProofLibrary',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'layerZeroToken',
        outputs: [
            {
                internalType: 'contract IERC20',
                name: '',
                type: 'address',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint16',
                name: '',
                type: 'uint16',
            },
        ],
        name: 'maxInboundProofLibrary',
        outputs: [
            {
                internalType: 'uint16',
                name: '',
                type: 'uint16',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_oracle',
                type: 'address',
            },
        ],
        name: 'oracleQuotedAmount',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
        ],
        name: 'oracleQuotedFees',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'owner',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_relayer',
                type: 'address',
            },
        ],
        name: 'relayerQuotedAmount',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
        ],
        name: 'relayerQuotedFees',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'renounceOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_ua',
                type: 'address',
            },
            {
                internalType: 'uint64',
                name: '_nonce',
                type: 'uint64',
            },
            {
                internalType: 'uint16',
                name: '_chainId',
                type: 'uint16',
            },
            {
                internalType: 'bytes',
                name: '_destination',
                type: 'bytes',
            },
            {
                internalType: 'bytes',
                name: '_payload',
                type: 'bytes',
            },
            {
                internalType: 'address payable',
                name: '_refundAddress',
                type: 'address',
            },
            {
                internalType: 'address',
                name: '_zroPaymentAddress',
                type: 'address',
            },
            {
                internalType: 'bytes',
                name: '_adapterParams',
                type: 'bytes',
            },
        ],
        name: 'send',
        outputs: [],
        stateMutability: 'payable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint16',
                name: '_chainId',
                type: 'uint16',
            },
            {
                internalType: 'uint256',
                name: '_size',
                type: 'uint256',
            },
        ],
        name: 'setChainAddressSize',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint16',
                name: 'chainId',
                type: 'uint16',
            },
            {
                internalType: 'address',
                name: '_ua',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: '_configType',
                type: 'uint256',
            },
            {
                internalType: 'bytes',
                name: '_config',
                type: 'bytes',
            },
        ],
        name: 'setConfig',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint16',
                name: '_chainId',
                type: 'uint16',
            },
            {
                internalType: 'uint16',
                name: '_proofType',
                type: 'uint16',
            },
            {
                internalType: 'bytes',
                name: '_adapterParams',
                type: 'bytes',
            },
        ],
        name: 'setDefaultAdapterParamsForChainId',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint16',
                name: '_chainId',
                type: 'uint16',
            },
            {
                internalType: 'uint16',
                name: '_inboundProofLibraryVersion',
                type: 'uint16',
            },
            {
                internalType: 'uint64',
                name: '_inboundBlockConfirmations',
                type: 'uint64',
            },
            {
                internalType: 'address',
                name: '_relayer',
                type: 'address',
            },
            {
                internalType: 'uint16',
                name: '_outboundProofType',
                type: 'uint16',
            },
            {
                internalType: 'uint16',
                name: '_outboundBlockConfirmations',
                type: 'uint16',
            },
            {
                internalType: 'address',
                name: '_oracle',
                type: 'address',
            },
        ],
        name: 'setDefaultConfigForChainId',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_layerZeroToken',
                type: 'address',
            },
        ],
        name: 'setLayerZeroToken',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint16',
                name: '_remoteChainId',
                type: 'uint16',
            },
            {
                internalType: 'bytes32',
                name: '_remoteUln',
                type: 'bytes32',
            },
        ],
        name: 'setRemoteUln',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_treasury',
                type: 'address',
            },
        ],
        name: 'setTreasury',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint16',
                name: '',
                type: 'uint16',
            },
            {
                internalType: 'uint16',
                name: '',
                type: 'uint16',
            },
        ],
        name: 'supportedOutboundProof',
        outputs: [
            {
                internalType: 'bool',
                name: '',
                type: 'bool',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'newOwner',
                type: 'address',
            },
        ],
        name: 'transferOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'treasuryContract',
        outputs: [
            {
                internalType: 'contract ILayerZeroTreasury',
                name: '',
                type: 'address',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'treasuryNativeFees',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'treasuryZROFees',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint16',
                name: '',
                type: 'uint16',
            },
        ],
        name: 'ulnLookup',
        outputs: [
            {
                internalType: 'bytes32',
                name: '',
                type: 'bytes32',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint16',
                name: '_srcChainId',
                type: 'uint16',
            },
            {
                internalType: 'bytes32',
                name: '_lookupHash',
                type: 'bytes32',
            },
            {
                internalType: 'uint256',
                name: '_confirmations',
                type: 'uint256',
            },
            {
                internalType: 'bytes32',
                name: '_data',
                type: 'bytes32',
            },
        ],
        name: 'updateHash',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint16',
                name: '_srcChainId',
                type: 'uint16',
            },
            {
                internalType: 'address',
                name: '_dstAddress',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: '_gasLimit',
                type: 'uint256',
            },
            {
                internalType: 'bytes32',
                name: '_lookupHash',
                type: 'bytes32',
            },
            {
                internalType: 'bytes',
                name: '_transactionProof',
                type: 'bytes',
            },
        ],
        name: 'validateTransactionProof',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint8',
                name: '_type',
                type: 'uint8',
            },
            {
                internalType: 'address',
                name: '_owner',
                type: 'address',
            },
            {
                internalType: 'address payable',
                name: '_to',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: '_amount',
                type: 'uint256',
            },
        ],
        name: 'withdrawNative',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_to',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: '_amount',
                type: 'uint256',
            },
        ],
        name: 'withdrawZRO',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
];
class UltraLightNode__factory {
    static createInterface() {
        return new ethers_1.utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new ethers_1.Contract(address, _abi, signerOrProvider);
    }
}
exports.UltraLightNode__factory = UltraLightNode__factory;
UltraLightNode__factory.abi = _abi;
