import {createWeb3OnboardChains} from '@layerzerolabs/ui-adapter-web3-onboard';
import coinbaseWalletModule from '@web3-onboard/coinbase';
import createWeb3Onboard, {OnboardAPI} from '@web3-onboard/core';
import injectedModule from '@web3-onboard/injected-wallets';

const logo = (height: number) => `<svg></svg>`;

const coinbaseWalletSdk = coinbaseWalletModule();

const OKX = {
  label: 'OKX',
  injectedNamespace: 'okxwallet',
  // @ts-ignore
  checkProviderIdentity: ({provider}) => !!provider && !!provider.isOKExWallet,
  getIcon: () => 'https://icons-ckg.pages.dev/lz-dark/wallets/okx.svg',
  getInterface: () => ({
    // @ts-ignore
    provider: window.okxwallet,
  }),
  platforms: ['desktop'],
};

const BraveWallet = {
  label: 'Brave',
  injectedNamespace: 'ethereum',
  // @ts-ignore
  checkProviderIdentity: ({provider}) => !!provider && !!provider.isBraveWallet,
  getIcon: () => 'https://icons-ckg.pages.dev/lz-dark/wallets/brave.svg',
  getInterface: () => ({
    provider: window.ethereum,
  }),
  platforms: ['desktop'],
};
// @ts-ignore - @web3-onboard/injected-wallets does not export types and expects
// injectedNamespace to be an enum that includes `ethereum`, but not a string
// TODO: migrate to current ui-wallet packages instead of custom implementation
const injected = injectedModule({custom: [BraveWallet, OKX]});

// need to try to restore because re-rerenders removes chains from somehow
export const web3Onboard: OnboardAPI = createWeb3Onboard({
  wallets: [
    //
    injected,
    coinbaseWalletSdk,
  ],
  appMetadata: {
    icon: logo(30),
    logo: logo(40),
    name: 'OFT BRIDGE',
    description: 'OFT BRIDGE by LayerZero',
  },
  chains: createWeb3OnboardChains(),
  accountCenter: {
    desktop: {enabled: false},
    mobile: {enabled: false},
  },
  notify: {
    enabled: false,
  },
});
