"use strict";
/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Relayer__factory = void 0;
const ethers_1 = require("ethers");
const _abi = [
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'address',
                name: 'addr',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'bool',
                name: 'approved',
                type: 'bool',
            },
        ],
        name: 'ApproveAddress',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'previousOwner',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'newOwner',
                type: 'address',
            },
        ],
        name: 'OwnershipTransferred',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'address',
                name: 'to',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
            },
        ],
        name: 'Withdraw',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'address',
                name: 'token',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'address',
                name: 'to',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
            },
        ],
        name: 'WithdrawTokens',
        type: 'event',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
        ],
        name: 'approvedAddresses',
        outputs: [
            {
                internalType: 'bool',
                name: '',
                type: 'bool',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint16',
                name: '',
                type: 'uint16',
            },
            {
                internalType: 'uint16',
                name: '',
                type: 'uint16',
            },
        ],
        name: 'dstConfigLookup',
        outputs: [
            {
                internalType: 'uint128',
                name: 'dstNativeAmtCap',
                type: 'uint128',
            },
            {
                internalType: 'uint64',
                name: 'baseGas',
                type: 'uint64',
            },
            {
                internalType: 'uint64',
                name: 'gasPerByte',
                type: 'uint64',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint16',
                name: '',
                type: 'uint16',
            },
        ],
        name: 'dstPriceLookup',
        outputs: [
            {
                internalType: 'uint128',
                name: 'dstPriceRatio',
                type: 'uint128',
            },
            {
                internalType: 'uint128',
                name: 'dstGasPriceInWei',
                type: 'uint128',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint16',
                name: '_dstChainId',
                type: 'uint16',
            },
            {
                internalType: 'uint16',
                name: '_outboundProofType',
                type: 'uint16',
            },
            {
                internalType: 'address',
                name: '_userApplication',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: 'payloadSize',
                type: 'uint256',
            },
            {
                internalType: 'bytes',
                name: '_adapterParams',
                type: 'bytes',
            },
        ],
        name: 'getPrice',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_uln',
                type: 'address',
            },
        ],
        name: 'initialize',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_relayerAddress',
                type: 'address',
            },
        ],
        name: 'isApproved',
        outputs: [
            {
                internalType: 'bool',
                name: '',
                type: 'bool',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint16',
                name: '_dstChainId',
                type: 'uint16',
            },
            {
                internalType: 'uint16',
                name: '_outboundProofType',
                type: 'uint16',
            },
            {
                internalType: 'bytes',
                name: '_adapterParams',
                type: 'bytes',
            },
        ],
        name: 'notifyRelayer',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'owner',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'renounceOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_relayerAddress',
                type: 'address',
            },
            {
                internalType: 'bool',
                name: '_approve',
                type: 'bool',
            },
        ],
        name: 'setApprovedAddress',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint16',
                name: '_chainId',
                type: 'uint16',
            },
            {
                internalType: 'uint16',
                name: '_outboundProofType',
                type: 'uint16',
            },
            {
                internalType: 'uint128',
                name: '_dstNativeAmtCap',
                type: 'uint128',
            },
            {
                internalType: 'uint64',
                name: '_baseGas',
                type: 'uint64',
            },
            {
                internalType: 'uint64',
                name: '_gasPerByte',
                type: 'uint64',
            },
        ],
        name: 'setDstConfig',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint16',
                name: '_chainId',
                type: 'uint16',
            },
            {
                internalType: 'uint128',
                name: '_dstPriceRatio',
                type: 'uint128',
            },
            {
                internalType: 'uint128',
                name: '_dstGasPriceInWei',
                type: 'uint128',
            },
        ],
        name: 'setDstPrice',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'newOwner',
                type: 'address',
            },
        ],
        name: 'transferOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'uln',
        outputs: [
            {
                internalType: 'contract ILayerZeroUltraLightNodeV1',
                name: '',
                type: 'address',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint16',
                name: '_srcChainId',
                type: 'uint16',
            },
            {
                internalType: 'address',
                name: '_dstAddress',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: '_gasLimit',
                type: 'uint256',
            },
            {
                internalType: 'bytes32',
                name: '_blockHash',
                type: 'bytes32',
            },
            {
                internalType: 'bytes',
                name: '_transactionProof',
                type: 'bytes',
            },
        ],
        name: 'validateTransactionProofV1',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint16',
                name: '_srcChainId',
                type: 'uint16',
            },
            {
                internalType: 'address',
                name: '_dstAddress',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: '_gasLimit',
                type: 'uint256',
            },
            {
                internalType: 'bytes32',
                name: '_blockHash',
                type: 'bytes32',
            },
            {
                internalType: 'bytes',
                name: '_transactionProof',
                type: 'bytes',
            },
            {
                internalType: 'address payable',
                name: '_to',
                type: 'address',
            },
        ],
        name: 'validateTransactionProofV2',
        outputs: [],
        stateMutability: 'payable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address payable',
                name: '_to',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: '_amount',
                type: 'uint256',
            },
        ],
        name: 'withdrawQuotedFromULN',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
];
class Relayer__factory {
    static createInterface() {
        return new ethers_1.utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new ethers_1.Contract(address, _abi, signerOrProvider);
    }
}
exports.Relayer__factory = Relayer__factory;
Relayer__factory.abi = _abi;
