"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProxyOFTV2Fee__factory = exports.ProxyOFTV2__factory = exports.OFTV2Fee__factory = exports.OFTV2__factory = void 0;
/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
var OFTV2__factory_1 = require("./OFTV2__factory");
Object.defineProperty(exports, "OFTV2__factory", { enumerable: true, get: function () { return OFTV2__factory_1.OFTV2__factory; } });
var OFTV2Fee__factory_1 = require("./OFTV2Fee__factory");
Object.defineProperty(exports, "OFTV2Fee__factory", { enumerable: true, get: function () { return OFTV2Fee__factory_1.OFTV2Fee__factory; } });
var ProxyOFTV2__factory_1 = require("./ProxyOFTV2__factory");
Object.defineProperty(exports, "ProxyOFTV2__factory", { enumerable: true, get: function () { return ProxyOFTV2__factory_1.ProxyOFTV2__factory; } });
var ProxyOFTV2Fee__factory_1 = require("./ProxyOFTV2Fee__factory");
Object.defineProperty(exports, "ProxyOFTV2Fee__factory", { enumerable: true, get: function () { return ProxyOFTV2Fee__factory_1.ProxyOFTV2Fee__factory; } });
